import { getFunctions, httpsCallable } from "firebase/functions";

export const saveEmail = async (email) => {
  const functions = getFunctions();
  const addEmail = httpsCallable(functions, "addEmail");

  try {
    const response = await addEmail({ email });
    console.log(response.data.message); // Success message
  } catch (error) {
    console.error("Error saving email:", error.message);
  }
};
